import styled from "styled-components"
import { device } from "../../themes/device"

const Item = styled.li`
  padding-left: 80px;
  display: inline-block;

  position: relative;
  box-sizing: border-box;

  @media ${device.tablet} {
    padding-top: 16px;
  }

  &:before {
    position: absolute;
    content: counters(section, ".") " ";
    top: -12px;
    left: 0;
    font-size: 22px;
    font-weight: bold;
    color: white;
    counter-increment: section;
    width: 58px;
    height: 58px;
    background: black;
    border-radius: 50%;
    text-align: center;
    line-height: 58px;

    @media ${device.tablet} {
      top: 6px;
    }

    @media ${device.laptop} {
      top: 9px;
    }
  }
`

export default Item
