export default [
  {
    title: "Kennenlernen.",
    description:
      "Gerne lernen wir Sie bei einem Kaffee kennen. Dabei nehmen wir uns genug Zeit für Sie und erstellen Ihnen eine unverbindliche Offerte.",
  },
  {
    title: "Konzept.",
    description:
      "Wir definieren Ihr Ziel. Es wird eine klare Struktur individuell auf Ihre Dienstleistungen entwickelt.",
  },
  {
    title: "Design.",
    description:
      "Wir bringen etwas Farbe ins Spiel. Ihre Website bekommt ein Look & Feel. Texte und Bilder werden positioniert.",
  },
  {
    title: "Prototyp.",
    description:
      "Ein klickbare Version ist für Smartphone, Tablet und Desktop bereit. Nun testen wir bis die Finger brennen.",
  },
  {
    title: "Online.",
    description:
      "Ihre Website macht es sich nun auf dem Server gemütlich. 24/7h zugänglich für Ihre Kunden.",
  },
]
