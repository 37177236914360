import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Hero2 from "../components/Hero2"
import LinkButton from "../components/LinkButton"
import H1 from "../components/H1"
import H2 from "../components/H2"
import P from "../components/P"
import Container from "../components/Container"
import Section from "../components/Section"
import Stepper from "../components/Stepper"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import responsive from "../images/responsive.svg"
import speedclock from "../images/speedclock.svg"
import web_enjoy from "../images/web_enjoy.svg"
import Contact from "../components/Contact"
import eightSeconds from "../images/8sec.svg"
import ImageBlock from "../components/ImageBlock"
import ContactForm from "../components/Contact-Form"
import { graphql } from "gatsby"

const WebentwicklungPage = ({ data }) => {
  console.log(data)

  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    position: {
      position: "relative",
    },
  }))
  const classes = useStyles()

  return (
    <Layout>
      <SEO title="Webentwicklung" />

      <Hero2 height={"100vh"}>
        <Container>
          <Grid container spacing={0}>
            <Grid item xs={12} md={6} className={classes.position}>
              <H1>Webentwicklung </H1>
              <P>
                Wir entwickeln Webseiten mit Konzept. Unsere Webseiten verfolgen
                Ihr Ziel.
              </P>
              <P>
                Mit unserer Erfahrung und Leidenschaft finden Sie Ihre Besucher
                nicht nur, Sie verweilen auch gerne auf Ihren Seiten und kaufen
                gerne.
              </P>
              <LinkButton to="/webentwicklung#eight-seconds">
                Was Sie wissen müssen
              </LinkButton>
            </Grid>
          </Grid>
        </Container>
        <ImageBlock
          alt="Webentwicklung"
          src={web_enjoy}
          width={"640px"}
          widthSm={"960px"}
          widthMd={"960px"}
          widthLg={"960px"}
          position={{
            position: "absolute",
            bottom: "-5%",
            right: "-120px",
            zIndex: "-1",
          }}
        />
      </Hero2>

      <Section id="eight-seconds" height={"100vh"} padding={"0 16px"}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={5} className={classes.position}>
              <H2 marginLg={"0 0 24px 0"}>
                Sie haben weniger als 8 Sekunden, um zu überzeugen
              </H2>
              <P>
                Innerhalb dieser Zeitspanne entscheiden
                <strong>
                  {""} guter Inhalt, eine übersichtliche Struktur, blitzschnelle
                  Ladezeiten
                </strong>{" "}
                und <strong>Design</strong>, ob der Besucher auf Ihrer Seite
                verweilen möchte oder schnell das Weite sucht.
              </P>

              <LinkButton to="/webentwicklung#loading">Ladezeiten?</LinkButton>
            </Grid>
            <Grid item xs={12} md={7}>
              <ImageBlock
                alt="8 Sekunden"
                height={"auto"}
                src={eightSeconds}
                width={"640px"}
                widthSm={"960px"}
                widthMd={"640px"}
                widthLg={"700px"}
                margin={"-50% 0 0 -100%"}
                marginSm={"-50% 0 0 -20%"}
                marginMd={"0 -20% 0 0"}
                position={{
                  position: "absolute",
                  bottom: "-5%",
                  right: "-300px",
                  zIndex: "-1",
                }}
                positionSm={{
                  position: "static",
                  zIndex: "-1",
                }}
                positionMd={{
                  position: "static",
                  zIndex: "-1",
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Section>

      <Section id="loading" className={classes.root}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6} className={classes.position}>
              <H2 margin={"0"} marginSm={"0"} marginMd={"0"} marginLg={"0"}>
                Ladezeiten
              </H2>
              <P margin={"0"}>1.. 2.. 3.. und tschüss.</P>
              <ImageBlock
                alt="Ladezeiten"
                src={speedclock}
                height={"auto"}
                width={"640px"}
                widthSm={"640px"}
                widthMd={"640px"}
                marginSm={"-10% 0 0 25%"}
                marginMd={"0 20% 0 -40%"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <P>
                Nicht nur Besucher, sondern auch moderne Suchmaschinen mögen
                keine <strong>langen Ladezeiten</strong>.
              </P>

              <P>
                Die Zeit ist <strong>kostbar</strong>. Ihre Besucher müssen
                selbst bei der geringsten Internetverbindung (z.B. im Zug) mit
                ihrer Website interagieren können.
              </P>

              <P>
                Wir entwickeln unsere Webseiten mit grossen Fokus auf
                Geschwindigkeit. Der Besucher muss nur Inhalte herunterladen,
                die er tatsächlich benötigt.
              </P>

              <LinkButton to="/webentwicklung#responsive">
                Ein Design für alles
              </LinkButton>
            </Grid>
          </Grid>
        </Container>
      </Section>

      <Section id="responsive" className={classes.root}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <ImageBlock
                alt="Respponsive"
                src={responsive}
                width={"140%"}
                widthSm={"130%"}
                widthMd={"100%"}
                margin={"0 0 8px -20%"}
                marginSm={"0 0 40px -20%"}
                marginMd={"0 -20% 0 0"}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.position}>
              <H2>Responsive Design</H2>
              <P>
                <strong>Ihre Website, in allen Grössen</strong>
              </P>
              <P>
                Besitzen Sie auch mehrere Endgeräte, um verschiedene Inhalte wie
                Video oder Text aus dem Internet zu konsumieren?{" "}
              </P>
              <P>
                Unsere modernen Webseiten passen sich automatisch an den
                gängigsten Grössen wie <strong>Mobile, Tablet</strong> oder{" "}
                <strong>Desktop</strong> an.
              </P>
              <P>
                Wir achten darauf, dass Abstände, Textgrössen und
                positinionierung der Elemente miteinander harmonieren, damit Ihr
                Besucher <strong>das beste Besucherlebnis hat</strong>.
              </P>

              <LinkButton to="/webentwicklung#steps">
                Ihre Website in 5 Schritten
              </LinkButton>
            </Grid>
          </Grid>
        </Container>
      </Section>

      <Section>
        <Container id="steps">
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <H2>Ihre Website in 5 Schritten</H2>
              <Stepper />
              <LinkButton to="/webentwicklung#ready">
                Jetzt Termin vereinbaren
              </LinkButton>
            </Grid>
          </Grid>
        </Container>
      </Section>

      <Section id="ready" marginBottomMd={"80px"}>
        <Container>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
              <H2>Bereit durchzustarten?</H2>
              <P>
                Sie haben Fragen oder sind bereit loszulegen? Perfekt!
                Kontaktieren Sie uns!
              </P>
            </Grid>
            <Grid item xs={12} md={6}>
              <Contact image={data.image1.childImageSharp.fluid} />
            </Grid>

            <Grid item xs={12} md={6} className={classes.position}>
              <ContactForm />
            </Grid>
          </Grid>
        </Container>
      </Section>
    </Layout>
  )
}

export default WebentwicklungPage

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "haris_bjelic.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 700, quality: 40) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
