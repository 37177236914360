import React from "react"
import Data from "./Items"
import Item from "./item"
import styled from "styled-components"
import Title from "./Title"
import P from "../P"

const StyledStepper = styled.ol`
  padding: 0;
  list-style-type: none;
  counter-reset: section;
`

const Stepper = () => {
  return (
    <StyledStepper>
      {Data.map((item, index) => (
        <Item key={index}>
          <Title> {item.title}</Title>
          <P>{item.description}</P>
        </Item>
      ))}
    </StyledStepper>
  )
}

export default Stepper
